<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="row pb30">
        <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          <AddressSearch @addressSelected="setAddress" />
        </div>
      </div>
      <div class="row pb30">
        <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          <div
            v-show="
              !show.manual &&
              data.address_line_1 &&
              data.post_code &&
              data.country
            "
            class="address"
          >
            <p>{{ data.address_line_1 }}</p>
            <p v-show="data.address_line_2">
              {{ data.address_line_2 }}
            </p>
            <p v-show="data.post_town">{{ data.post_town }}</p>
            <p>{{ data.post_code }}</p>
            <p>{{ data.country }}</p>
          </div>
        </div>
      </div>
      <div v-show="show.manual">
        <div class="row">
          <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
            <div class="input-container">
              <input
                ref="address1"
                @keyup.enter="$refs.address2.focus()"
                v-model="data.address_line_1"
                required
                id="address_line_1"
                type="text"
                placeholder="House Name or Number"
                class="input-standard-white validation"
              />
              <label for="address_line_1">House Name or Number</label>
            </div>
            <div class="input-container">
              <input
                @keyup.enter="$refs.posttown.focus()"
                ref="address2"
                v-model="data.address_line_2"
                id="address_line_2"
                type="text"
                placeholder="Street Name"
                class="input-standard-white validation"
              />
              <label for="address_line_2">Street Name</label>
            </div>
            <div class="input-container">
              <input
                ref="posttown"
                @keyup.enter="$refs.postcode.focus()"
                v-model="data.post_town"
                required
                id="post_town"
                type="text"
                placeholder="Post Town"
                class="input-standard-white validation"
              />
              <label for="post_town">Post Town</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
            <div class="input-container fadeIn-5">
              <input
                ref="postcode"
                @keyup.enter.stop="focusCountry = true"
                v-model="data.post_code"
                required
                id="post_code"
                type="text"
                placeholder="Postcode"
                class="input-standard-white validation"
              />
              <label for="post_code">Postcode</label>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
            <CountrySelect
              :focus="focusCountry"
              @enter="forward"
              v-bind:selectedCountry="data.country"
              @selectedCountry="setCountry"
              :required="true"
            ></CountrySelect>
          </div>
        </div>
      </div>
      <div class="row pb30">
        <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          <p
            @click="show.manual = !show.manual"
            class="align-right link text-small"
          >
            Edit Address
          </p>
        </div>
      </div>

      <div class="row pb30">
        <div class="col-lg-8 col-md-10 col-sm-12 col-xs-12">
          <div class="moving">
            <div class="row">
              <div class="col-lg-1 col-md-1 col-sm-1 col-1">
                <div
                  @click="showMovingModal"
                  id="terms-label"
                  class="css-checkbox dark inline-block"
                >
                  <input
                    type="checkbox"
                    id="authorised"
                    v-model="data.moving"
                  />
                  <label for="authorised"><span></span></label>
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-sm-10 col-10">
                <p class="check">I am in the process of moving home</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MovingModal
        v-show="show.moving"
        @close="show.moving = false"
        @confirm="show.moving = false"
      />

    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import AddressSearch from '@/common/components/AddressSearch'
import CountrySelect from '@/common/components/CountrySelect'
import MovingModal from '@/views/pages/ProfilePage/Questions/Modals/MovingModal'

export default {
  name: 'ProfileAddress',
  components: {
    MovingModal,
    NavQuestion,
    NavQuestionButtons,
    AddressSearch,
    CountrySelect
  },
  mounted() {
    this.$emit('progress', '60%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      return !!(
        this.data.address_line_1 &&
        this.data.post_town &&
        this.data.post_code &&
        this.data.country
      )
    },
    number() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '8'
      return '6'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    heading: () => 'What is your address?',
    subHeading: () =>
      'Your address is included on your Will for identification purposes only. If you are in the ' +
      'process of moving home please enter your current ' +
      'address (you can easily update your address after you have moved, although it is not ' +
      'strictly necessary).',
    start() {
      return this.$router.history.current.name === 'WriteMyWillAddress'
    },
    forwardTo() {
      // if (this.start) return '/write_my_will/marital_status'
      // return '/profile/marital_status'
      if (this.start) return '/write_my_will/residential_status'
      return '/profile/residential_status'
    },
    backTo() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      ) {
        if (this.personalDetails.charity_donate === 'amount') {
          if (this.start) return '/write_my_will/charity_amount'
          return '/profile/charity_amount'
        } else if (this.personalDetails.charity_donate === 'percentage') {
          if (this.start) return '/write_my_will/charity_percentage'
          return '/profile/charity_percentage'
        }
        if (this.start) return '/write_my_will/charity_option'
        return '/profile/charity_option'
      } else if (this.start) return '/write_my_will/pets'
      return '/profile/pets'
    }
  },
  watch: {
    personalDetails: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.data.address_line_1 = value.address_line_1
          this.data.address_line_2 = value.address_line_2
          this.data.post_town = value.post_town
          this.data.post_code = value.post_code
          this.data.country = value.country
          this.data.moving = value.moving
        }
      }
    }
  },
  data() {
    return {
      data: {
        address_line_1: null,
        address_line_2: null,
        post_town: null,
        post_code: null,
        country: null,
        moving: null
      },
      show: {
        manual: false,
        moving: false
      },
      focusCountry: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', this.data)
      }
      if (!this.data.moving) {
        // reset assets section
        this.$store.commit('assets', {new_residential_property_amount: null})
        this.$store.commit('assets', {new_residential_property: false})
        this.$store.commit('assets', {new_residential_mortgage: false})
        this.$store.commit('assets', {new_residential_mortgage_amount: null})


      }
    },
    setAddress(value) {
      if (value) {
        this.data.address_line_1 = value.line_1
        this.data.address_line_2 = value.line_2
        this.data.post_town = value.post_town
        this.data.post_code = value.postcode
        this.data.country = value.country
      }
    },
    setCountry(value) {
      this.data.country = value
    },
    forward() {
      if (this.isValidated) {
        this.$router.push(this.forwardTo).catch((error) => {
          console.log(error)
        })
      }
    },
    showMovingModal() {
      if (!this.data.moving) this.show.moving = true
    }
  }
}
</script>

<style scoped lang="scss">
.address {
  background-color: rgba(#345a7a, 0.7);
  padding: 30px;
  border-radius: 10px;
  color: #ffffff;
}

.moving {
  background-color: rgba(#e56781, 0.7);
  padding: 30px 30px 10px 30px;
  border-radius: 10px;
  color: white;
}

.address p {
  margin: 0px;
}

.text-small {
  font-size: 14px;
}
</style>
