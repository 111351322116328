<template>
  <div class="input-container">
    <select
      ref="country"
      @keyup.enter.stop="$emit('enter')"
      v-model="country"
      v-on:change="$emit('selectedCountry', country)"
      class="nice-select input-standard-white white validation"
      :required="required"
      id="country"
    >
      <option :value="null" disabled>Country</option>
      <option value="England">England</option>
      <option value="Scotland">Scotland</option>
      <option value="Wales">Wales</option>
      <option value="Northern Ireland">Northern Ireland</option>
    </select>
    <label :class="{ 'label-white': whiteLabel }" for="country">Country</label>
  </div>
</template>
<script>
export default {
  name: 'CountrySelect',
  props: ['selectedCountry', 'whiteLabel', 'required', 'focus'],
  data() {
    return {
      country: null
    }
  },
  watch: {
    selectedCountry: {
      deep: true,
      immediate: true,
      handler(value) {
        this.country = value
      }
    },
    focus(value) {
      if (value) this.$refs.country.focus()
    }
  }
}
</script>
