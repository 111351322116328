<template>
  <BaseModalSmall
    @close="close"
    v-bind:isValidated="true"
    @confirm="confirm"
    :noSlide="true"
  >
    <template v-slot:header>Moving Home</template>
    <template v-slot:body>
      <div>
        <p class="highlight">
          Once you have moved home you do not need to update the address on your
          Will, this is because your address appears on your Will simply as a
          means of identification and whilst your address may change over the
          years but your wishes are likely to remain the same.
        </p>

        <h5>When does moving make a difference?</h5>
        <p>
          You should update your will after you have moved home if you intend to
          gift your property specifically in your Will (instead of leaving
          assets to different people by a 'percentage split') or, if different
          people will be living with you after the move.
        </p>
      </div>
    </template>
    <template v-slot:button> I Understand </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'MovingModal',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  },
  computed: {
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    paymentRequired() {
      if (this.coupon)
        return (
          !this.coupon.price_discount_percentage === 100 ||
          !this.coupon.price_fixed_single === 0
        )
      else return true
    }
  }
}
</script>

<style scoped>
.highlight {
  padding: 15px;
  background-color: #faf9fe;
  color: #2f2c2c;
}
h5 {
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
p {
  margin: 0 0 0.75em;
  line-height: 1.7em;
  font-size: 18px;
}
</style>
